<template>
  <div class="vf-breadcrumb" :class="{'special': type}">
    <div v-for="(item,index) in data" :key="index" class="crumb">
        <img v-if="item.path === '/'" :src="require(`../assets/svg/home_${type ? 'white' : 'default'}_icon.svg`)" alt="">
        <span :class="{'spanback': item.back}" v-if="!item.path" @click="goBack(item.back)">{{item.breadcrumbName}}</span>
        <router-link  v-else :to="item.path" replace>
          {{item.breadcrumbName}}
        </router-link>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'VfBreadcrumb',

    props: {
      type: String,
      data: Array
    },
    methods: {
      goBack(v){
        if(!v) return
        this.$router.go(-1)
      }
    },
  }
</script>

<style lang="scss" scoped>
  .vf-breadcrumb {
    cursor: default;
    display: flex;
    align-items: center;
    color: rgba(51, 59, 69, 0.50);
    font-size: 12px;
    .crumb{
      height: 24px;
      line-height: 24px;
      position: relative;
      a{
        color: rgba(51, 59, 69, 0.50);
      }
      a:hover {
        color: #E52A0D;
      }
      img{
        position: relative;
        top: 3px;
      }
    }
    .crumb:not(:last-child):after{
      content: "";
      display: inline-block;
      width: 7px;
      height: 7px;
      border-top: 1px solid rgba(51, 59, 69, 0.50);
      border-right: 1px solid rgba(51, 59, 69, 0.50);
      transform: rotate(45deg);
      margin: 0 10px 0 4px;
      position: relative;
      top: -1px;
    }
  }
  .spanback{
    cursor: pointer;
    &:hover{
      color: #E52A0D;
    }
  }
  .special{
    color: #fff;
    .crumb{
      a{
        color: #fff;
        opacity: .7;
      }
      a:hover{
        color: #fff;
        opacity: 1;
        font-weight: 600;
      }
    }
    .crumb:not(:last-child):after{
      content: "";
      border-top: 1px solid #fff;
      border-right: 1px solid #fff;
    }
  }
</style>
