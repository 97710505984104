<template>
  <div class="videos-card video-custom-height" @mouseenter="handleMouseover" @mouseleave="handleMouseleave">
    <router-link :to="`/video/detail?videoId=${video.music_id}${isFree ? '&free=true': ''}`" style="width: 100%;height: 100%;">
      <video ref="video" muted :id="videoId" :poster="video.watermark_file + '?vframe/jpg/offset/1/w/280/h/180'">
        <source :src="video.watermark_file" />
      </video>
    </router-link>
    <i v-if="loading" class="loading el-icon-loading"></i>
    <div v-if="video.isPlaying" class="operation_icons">
      <div class="video-deed-item-media" @click="handleVideoCollect(video)">
        <img
          :src="`${video.is_collect === 1 ? require('@/assets/svg/operation/collected.svg') : require('@/assets/image/centerVideo/collect.png')}`"
          alt="">
      </div>
      <div
        class="video-deed-item-media"
        @click="handleShare('', video.object_id, video.type || 4, video.name)"
        @mouseover="mouseOver('shareHover')"
        @mouseleave="mouseLeave('shareHover')"
      >
        <img :src="require(`@/assets/image/centerVideo/share${shareHover?'-hover': ''}.png`)" alt="">
      </div>
      <div
        class="video-deed-item-media"
        @click="$emit('download', video)"
        @mouseover="mouseOver('downloadHover')"
        @mouseleave="mouseLeave('downloadHover')"
      >
        <img :src="require(`@/assets/image/centerVideo/down${downloadHover?'-hover': ''}.png`)" alt="">

      </div>
      <!-- <div class="video-deed-item-media">
        <img src="~@/assets/image/centerVideo/cart.png" alt="" @click="handleAddGoodsToCart(video, 4)">
      </div> -->
    </div>
  </div>
</template>

<script>
import Buttons from '@/layout/mixins/Buttons';
export default {
  props: {
    video: {
      type: Object,
      default: () => { },
    },
    isFree: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      downloadHover: false,
      shareHover: false,
      collectHover: true,
      loading: false, // 播放loading
      videoUrl: "", // 视频地址
      randomId: Math.random() * 10000, // video_id会出现重复的？？
    };
  },
  mixins: [Buttons],
  computed: {
    videoId() {
      return `video_player_${this.video.video_id}_${this.randomId}`;
    },
    videfRef() {
      return document.getElementById(this.videoId);
    },
  },
  mounted() {
    this.videfRef?.addEventListener("canplaythrough", () => {
      this.loading = false;
      if (this.video.isPlaying) {
        this.videfRef.play();
      }
    });
    this.videfRef?.addEventListener("play", () => {
      //播放开始执行的函数
      if (this.videfRef.readyState !== 4) {
        this.loading = true;
      }
    });
  },
  methods: {
    // 移入
    mouseOver(key, isCollect) {
      if (key === "collectHover" && isCollect) return;
      this[key] = true;
    },
    // 移出
    mouseLeave(key) {
      this[key] = false;
    },
    async handleVideoCollect(item) {
      item.is_collect = item.is_collect === 0 ? 1 : 0
      await this.handleCollect(item.object_id, 4, {
        ...item,
        type: 4,
        labels: item.label
      }, false)
    },
    // 移入视频区域
    handleMouseover() {
      this.$set(this.video, "isPlaying", true);
      this.videfRef.play();
      this.$emit('hover', this.video.object_id)
    },
    // 移出视频区域
    handleMouseleave() {
      this.$set(this.video, "isPlaying", false);
      this.loading = false;
      this.videfRef.load();
      this.$emit('hover', '')
    },
  },
};
</script>

<style lang="scss" scoped>
.videos-card {
  height: 100%;
  box-sizing: border-box;
  cursor: pointer;
  position: relative;

  .loading {
    font-size: 30px;
    color: #000000;
    position: absolute;
    top: 50%;
    right: 45%;
    transform: translate(-50%, -50%);
  }

  .operation_icons {
    position: absolute;
    right: 12px;
    top: 8px;

    .video-deed-item-media {
      @include center-middle-flex;
      background: rgba(0, 0, 0, 0.5);
      backdrop-filter: blur(1px);
      border-radius: 4px;
      margin-bottom: 4px;
    }
  }

  video {
    @include size(100%, 100%);
    object-fit: fill;
  }
}
</style>
